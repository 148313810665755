.Services {
  display: flex;
  font-family: var(--font-family);
  min-height: 80vh;
}

.Services-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.Services-content h1 {
  color: var(--color-text);
  font-size: 2.5em;
  font-weight: 450;
  margin-bottom: 3rem;
}

.Services-desc-container {
  display: flex;
  flex-direction: row;
}

.Services-desc-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  align-items: center;
}

.Services-desc-header p {
  margin-bottom: 1rem;
}

.Services-desc {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-right: 3rem;
  margin-left: 4rem;
}

.Services-desc h2 {
  color: var(--color-text);
  margin-top: 1rem;
  font-weight: 250;
}

.Services-desc p {
  color: var(--color-text2);
  margin-top: 1rem;
  font-weight: 10;
  max-width: 20rem;
  width: 100%;
}

.Services-desc h3 {
  margin-top: 3rem;
}

.Services-prices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.Services-prices h3 {
  font-weight: 100;
}

.Services-prices h1 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.Services-prices-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 7rem;
  margin-top: 1rem;
}

.Services-prices-item {
  margin-left: 4rem;
  margin-right: 4rem;
}

@media screen and (max-width: 1050px) {
  .Services {
    margin: 0 3rem 0 3rem;
  }

  .Sevices-content {
    align-items: center;
    flex-direction: column;
    margin-right: 0rem;
  }

  .Services-desc {
    text-align: center;
    margin-top: 3rem;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .Services-desc-container {
    margin: 0;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .Services {
    text-align: center;
  }
  .Services-prices-container {
    flex-direction: column;
  }
  .Services-prices-item {
    margin: 1rem 0rem 1rem 0rem;
  }
  .Services-prices h1 {
    margin-bottom: 0;
  }
}
